.blueBtn{
    color:aliceblue;
    background-color: var(--blueViolet);
    padding: .2rem .8rem;
    border-radius: 20px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow:1px 1px 1px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
}
.blueBtn:hover{
    background: var(--darkBlueViolet)!important;
    color: var(--magentaColor)!important;
    box-shadow: 2px 1px 4px var(--magentaColor);
    -webkit-box-shadow:2px 1px 4px var(--magentaColor);
    -moz-box-shadow:2px 1px 4px var(--magentaColor);
    transition: all .5s;
    -webkit-transition: all .5s;
}

.maroonBtn{
    color:var(--mainLight);
    background-color: var(--maroonColor);
    padding: .2rem .8rem;
    border-radius: 20px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow:1px 1px 1px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
}
.maroonBtn:hover{
    background: var(--maroonDarkColor)!important;
    color: var(--yellowColor)!important;
    box-shadow: 2px 1px 4px var(--yellowColor);
    -webkit-box-shadow:2px 1px 4px var(--yellowColor);
    -moz-box-shadow:2px 1px 4px var(--yellowColor);
    transition: all .5s;
    --webkit-transition: all .5s;
}
.yellowBtn{
    color: var(--maroonColor);
    background-color: #e6e600;
    padding: .2rem .8rem;
    border-radius: 20px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow:1px 1px 1px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
}
.yellowBtn:hover, .greenBtn:hover{
    background: var(--maroonDarkColor)!important;
    color: var(--yellowColor)!important;
    box-shadow: 2px 1px 4px var(--yellowColor);
    -webkit-box-shadow:2px 1px 4px var(--yellowColor);
    -moz-box-shadow:2px 1px 4px var(--yellowColor);
    transition: all .5s;
    -webkit-transition: all .5s;
}
.greenBtn{
    color:var(--maroonColor);
    background-color: #1affd1;
    padding: .2rem .8rem;
    border-radius: 20px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow:1px 1px 1px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.4);
}